
.user-agreement {
    margin-left: 20%;
    width: 60%;
    padding: 20px;
    text-align: left;
}

.user-agreement h1 {
    font-size: 2em;
}

.user-agreement p {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-indent: 2em;
}

.user-agreement ul {
    padding-left: 40px;
}

.user-agreement ul li {
    margin-bottom: 10px;
}