.about {
  width: 80%;
  margin-left: 10%;
  padding: 20px;
  text-align: left;
}

.about h1 {
  font-size: 2em;
}

.about p {
  font-size: 1.2em;
}