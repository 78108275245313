.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-left: 20%;
    width: 60%;
}

.navbar  a {
    text-decoration: none;
    color: black;
}
