.carousel .carousel .slide {
    height: 150px;
    overflow: hidden;
}
.carousel-slide {
    display: flex;
}

.carousel .slide img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: center;
}

.carousel {
    margin-top: 10px;
    margin-bottom: 10px;
}

.introduction {
    word-wrap: break-word;
    max-width: 70%;
    padding: 20px 100px;
    /*虚线边框*/
    /*border: 1px dashed #ccc;*/
    border-radius: 4px;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);*/
    margin: 20px auto;
}

.introduction h2 {
    float: left;
    margin-right: 20px;
}
.introduction p {
    text-indent: 2em;
    text-align: left;
}

.product-intro-pz {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.product-intro-pz div {
    width: 100%;
    object-fit: cover;
}

.linker {
    width: 70%;
    margin: 0 auto;
    height: 200px;
    justify-content: space-between;
}

.linker h2 {
    float: left;
    margin-right: 20px;
}

.linker p {
    text-indent: 2em;
    text-align: left;
}