.product-intro {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.product-intro h1 {
    font-size: 2em;
}

.product-intro p {
    font-size: 1.2em;
}

.product-intro img {
    width: 100%;
    height: auto;
}
.product-intro div {
    width: 100%;
    object-fit: cover;
}