
.privacy-policy {
  margin-left: 20%;
  width: 60%;
  padding: 20px;
  /*左对齐*/
    text-align: left;
}

.privacy-policy h1 {
  font-size: 2em;
}

.privacy-policy p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.privacy-policy ul {
  padding-left: 40px;
}

.privacy-policy ul li {
  margin-bottom: 10px;
}